<!-- [ TEMPLATE ] -->
<template lang="en">
    <div style="width:100%">
        <h2>Education</h2>
        <p>Timeline & Awards</p>

        <!-- Content -->
        <div class="container">

            <!-- Education Timeline -->
            <div class="half">
                <section v-if="education" v-for="item in education">
                    <div class="edu-card hover">
                        <div class="edu-content">
                            <h4>{{ item.School }}</h4>
                            <div class="indent subtle">
                                <p>{{ item["Year"].value }}</p>
                                <p>{{ item["Degree"].value }}</p>
                            </div>
                            <div class="indent">
                                <p><span class="highlight">CCA:</span> {{ item["CCA"].value }}</p>
                            </div>
                            <div class="indent">
                                <p><span class="highlight">Leadership Roles:</span> {{ item["Leadership Role(s)"].value }}</p>
                            </div>
                        </div>

                        <section class="edu-image" v-bind:style="{ 'background-image': 'url(' + item.Image.files[0].file.url + ')' }"></section>
                    </div>
                </section>
            </div>

            <!-- Language List -->
            <div class="half awards-conent">
                <section v-if="awards" v-for="award in awards">
                    <atropos-component>
                        <a target="_blank" :href="award.Image.files[0].file.url" data-atropos-offset="0">
                            <img 
                                class="award-image" 
                                v-bind:src="award.Image.files[0].file.url" 
                                alt="award"
                                v-tooltip="award.Name + ' (' + award.Year.value + ')'"
                                style="object-fit: cover;"
                                data-atropos-offset="1"
                            >
                        </a>
                    </atropos-component>
                </section>
            </div>
        </div>
    </div>
</template>

<!-- [ SCRIPT ] -->
<script>
import { GetEducation, GetAwards } from "../functions/education.js";

// Import Atropos
// import AtroposComponent from 'atropos/element';
// customElements.define('atropos-component', AtroposComponent);

export default {
    data: () => ({
        dummy: ["a", "b", "c"],
        education: [],
        awards: [],
    }),
    created() {
        GetEducation().then((data) => {
            this.education = data;
        });

        GetAwards().then((data) => {
            this.awards = data;
        });
    },
};
</script>

<!-- [ STYLING ] -->
<style>
.edu-card {
    display: flex;
    margin: 1rem 0rem;
    gap: 1rem;

    --b: 2px; /* thickness of the border */
    --c: rgba(233, 233, 233, 0.103); /* color of the border */
    --w: 20px; /* width of border */
    border: var(--b) solid #0000; /* space for the border */
    --_g: #0000 90deg, var(--c) 0;
    --_p: var(--w) var(--w) border-box no-repeat;
    background: conic-gradient(
                from 90deg at top var(--b) left var(--b),
                var(--_g)
            )
            0 0 / var(--_p),
        conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g))
            100% 0 / var(--_p),
        conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0
            100% / var(--_p),
        conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g))
            100% 100% / var(--_p);

    background-color: rgba(3, 3, 3, 0.37);
    font-size: 0.8rem;
}

.edu-content {
    padding: 1rem;
    flex-basis: 100%;
    flex-wrap: wrap;
}

.edu-image {
    flex-basis: 25%;
    /* background-image: url('../assets/images/add.jpg'); */
    background-size: cover;
    background-position: center center;
}

.awards-conent {
    display: flex;
    padding: 1rem;
    flex-wrap: wrap;
    flex-direction: row !important;
    gap: 1rem;
    height: 100%;
    /* max-height: 40vh; */

    overflow: scroll;
}

.award-image {
    width: auto;
    height: 10rem;
    object-fit: cover;
}

.award-image:hover {
    transition: all 0.3s ease-in-out;
    transform: scale(1.05);
    filter: drop-shadow(-20px 20px 10px #00000069);
    cursor: pointer;
}
</style>
